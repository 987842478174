import * as React from "react"
import * as styles from "../styles/blocks.module.css"
import {extractSrcFromIframe} from "../utils/utils"

const BottomPage = ({ mapWStats, mapScript }) => {
    return (
        <>
            {mapScript && (
                <iframe
                    title="Kota Wisata"
                    src={extractSrcFromIframe(mapScript)}
                    width="100%"
                    height={400}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                />
            )}
            <div className={styles.numbers}>
                <div className="container mx-auto">
                    <ul className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-6">
                        {mapWStats?.stats.map((n, i) => {
                            return (
                                <li
                                    key={`nu-${i}`}
                                    className={`mb-8 lg:mb-0 ${styles.numberItem}`}
                                >
                                    <span className={styles.numVal}>
                                        {n.value}
                                    </span>
                                    <span
                                        className={`font-serif ${styles.numLabel}`}
                                    >
                                        {n.label}
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default BottomPage
