import * as React from "react"
import axios from "axios"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/pageheader"
import * as styles from "../styles/contact.module.css"
import { Button } from "../components/buttons"
import PageBottom from "../components/pagebottom"
import { graphql } from "gatsby"

const ContactUsForm = ({ handleSubmit }) => {
    return (
        <div className="bg-white">
            <div className={`mx-auto pt-4 pb-24 ${styles.wrap}`}>
                <form
                    onSubmit={handleSubmit}
                    className={`mx-4 lg:mx-0 ${styles.contactForm}`}
                >
                    <div className={styles.formRow}>
                        <div>
                            <label
                                className={styles.checkBox}
                                htmlFor="kota-wisata-resident"
                            >
                                <input
                                    type="checkbox"
                                    name="isKotaWisataResidence"
                                    value="Yes"
                                />
                                Penghuni Kota Wisata
                            </label>
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div>
                            <label htmlFor="mailTo">
                                Email ke{" "}
                                <span className={styles.required}>*</span>
                            </label>
                            <select name="mailTo">
                                <option value="sales">Sales</option>
                            </select>
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div>
                            <label htmlFor="name">
                                Nama <span className={styles.required}>*</span>
                            </label>
                            <input
                                required
                                name="fullName"
                                type="text"
                                placeholder="Masukan nama anda"
                            />
                        </div>
                    </div>
                    <div className={`flex ${styles.formRow}`}>
                        <div className="w-1/2">
                            <label htmlFor="email">
                                Email <span className={styles.required}>*</span>
                            </label>
                            <input
                                required
                                name="email"
                                type="email"
                                placeholder="name@email.com"
                            />
                        </div>
                        <div className="w-1/2">
                            <div>
                                <label htmlFor="phone">
                                    Nomor Telepon{" "}
                                    <span className={styles.required}>*</span>
                                </label>
                                <div className={styles.withPrefix}>
                                    <span>+62</span>
                                    <input
                                        required
                                        name="phoneNumber"
                                        type="number"
                                        placeholder="812345xxxx"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div>
                            <label htmlFor="message">
                                Pesan <span className={styles.required}>*</span>
                            </label>
                            <textarea
                                required
                                name="message"
                                rows={10}
                                placeholder="Tulis pesan anda"
                            />
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div>
                            <Button isFull>Kirim</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

const ContactUsPage = ({ data }) => {
    const handleSubmit = event => {
        event.preventDefault()
        const form = new FormData(event.target)
        const formData = Object.fromEntries(form)
        const query = JSON.stringify({
            query: `mutation FormContactUs(
                $email: String,
                $fullName: String,
                $isKotaWisataResidence: String,
                $message: String,
                $phoneNumber: String) {
              submit2FormContactUs(
                input: {
                    clientMutationId: "", 
                    email: $email,
                    fullName: $fullName,
                    message: $message,
                    phoneNumber: $phoneNumber,
                    isKotaWisataResidence: $isKotaWisataResidence
                }
              ) {
                clientMutationId
                message
                status
              }
            }`,
            variables: {
                ...formData,
                phoneNumber: "62" + formData?.phoneNumber,
            },
        })

        const config = {
            method: "POST",
            url: process.env.WP_URL,
            headers: {
                "Content-Type": "application/json",
            },
            data: query,
        }

        axios(config)
            .then(function (response) {
                alert("Pesan anda berhasil dikirim. Terimakasih!")
                window.location.reload()
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    return (
        <Layout activeMenuId={7}>
            {data?.wpPage?.translations?.map(translate => {
                return translate?.blocks.map((item, i) => {
                    switch (item?.name) {
                        case "acf/cover-main":
                            const coverMain = item?.coverMain
                            return (
                                <React.Fragment key={i}>
                                    <Seo
                                      title={coverMain?.title}
                                    description={data?.wpPage?.seo?.metaDesc}
                                    date={data?.wpPage?.date}
                                    img={
                                        data?.wpPage?.seo?.opengraphImage
                                            ?.mediaItemUrl
                                    }
                                    imgHeight={
                                        data?.wpPage?.seo?.opengraphImage
                                            ?.mediaDetails?.height
                                    }
                                    imgWidth={
                                        data?.wpPage?.seo?.opengraphImage
                                            ?.mediaDetails?.width
                                    }
                                    />
                                    <PageHeader
                                        title={coverMain?.title}
                                        subTitle={coverMain?.text}
                                        image={coverMain?.img?.mediaItemUrl}
                                        breadcrumbs={[
                                            {
                                                label: coverMain?.title,
                                                link: null,
                                            },
                                        ]}
                                    />
                                    <ContactUsForm
                                        handleSubmit={handleSubmit}
                                    />
                                </React.Fragment>
                            )
                        case "acf/experiment":
                            const mapWStats = item?.mapWStats
                            return <PageBottom key={i} mapWStats={mapWStats} />
                    }
                })
            })}
        </Layout>
    )
}

export const query = graphql`
    {
        wpPage(slug: { eq: "contact-us" }) {
            date
            seo {
                title
                metaDesc
                opengraphImage {
                    id
                    mediaItemUrl
                    mediaDetails {
                        height
                        width
                    }
                }
            }
            translations {
                blocks {
                    name
                    ... on WpAcfCoverMainBlock {
                        coverMain {
                            img {
                                mediaItemUrl
                            }
                            text
                            title
                        }
                    }
                    ... on WpAcfTextWColCardsBlock {
                        textWColCards {
                            cards {
                                title
                                link {
                                    url
                                }
                                img {
                                    mediaItemUrl
                                }
                            }
                        }
                    }
                    ... on WpAcfExperimentBlock {
                        mapWStats {
                            mapEmbed
                            stats {
                                label
                                value
                            }
                        }
                    }
                }
            }
        }
    }
`

export default ContactUsPage
